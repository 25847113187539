<template>
  <div class="container">
    <div class="navbar-header">
      <a class="navbar-brand" href="#"
        >Симулятор Собеседования</a
      ><br /><br />
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>